import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ManifestoId } from "./ManifestoContext";
import { StatementId } from "./StatementsContext";
import { CommentId } from "../services/ReactionService";
import { useAuthUserContext } from "./UserContext";


export type UserID = string;

export interface IUser {
  uuid: UserID;
  name: string;
  email?: string;
  avatar?: string;
  manifestoIds?: ManifestoId[];
  lastComments?: { statementId: StatementId; commentId: CommentId }[];
  moderator?:boolean | undefined;
  deleted?:boolean | undefined;
  created: string;
}

export type OptionalIUser = IUser | undefined;

interface IUsersContext {
  users: IUser[];
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  getUser: (id: UserID) => OptionalIUser;
  //stack: UsersStack;
}

const UsersContext = createContext<IUsersContext | undefined>(
  undefined
);

export const UsersProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const { API } = useAuthUserContext();
  //const stack = useUsersStack(users);

  const getUser = useCallback(
    (id: UserID): OptionalIUser => {
      return users?.find((user) => user.uuid === id);
    },
    [users]
  );

  useEffect(() => {
    API && API.get("/users").then((res) => setUsers(res.data));
  }, [API]);

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        getUser,
        //stack,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = () => useContext(UsersContext)!;
