import React, { createContext, ReactNode, useContext, useState } from "react";

export interface IActionBar {
  main?: ReactNode;
  mainLeft?: ReactNode;
  mainRight?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

interface IMenuContext {
  showMenu: boolean;
  setShowMenu: (show: boolean) => void;
  toggleShowMenu: () => void;
  actionBar?: IActionBar;
  setActionBar: (actionBar?: IActionBar) => void;
}

const MenuContext = createContext<IMenuContext | undefined>(undefined);

export const MenuProvider: React.FC = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [actionBar, setActionBar] = useState<IActionBar>();

  const toggleShowMenu = () => {
    setShowMenu((show) => !show);
  };

  // const xxsetActionBar = _setActionBar;
  // const xsetActionBar = (actionBar?: IActionBar) => {
  //   _setActionBar(actionBar);
  //   return () => {
  //     //_setActionBar(undefined);
  //   };
  // };

  return (
    <MenuContext.Provider
      value={{ showMenu, setShowMenu, toggleShowMenu, actionBar, setActionBar }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext)!;
