import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IStatement, useStatementsContext } from "./StatementsContext";
import { immutable as unique } from "array-unique";

export type AverageManifestoId = string;
export interface IAverageManifesto {
  statements: IStatement[];
  publicCount: number;
}

interface IAverageManifestoContext {
  myAverageManifesto?: IAverageManifesto;

  setPublicCount: (publicCount: number) => void;

  add: (statement: IStatement) => void;
  remove: (statement: IStatement) => void;
  contains: (statement: IStatement) => boolean;

  // reorder: (statement: IStatement, to: number) => void;
  reorder: (from: number, to: number) => void;

}

const AverageManifestoContext = createContext<IAverageManifestoContext | undefined>(
  undefined
);

export const AverageManifestoProvider: React.FC = ({ children }) => {
  const [statements, setStatements] = useState<IStatement[]>([]);
  const [publicCount, _setPublicCount] = useState<number>(0);
  const [myAverageManifesto, setMyAverageManifesto] = useState<IAverageManifesto>();
  const [myAverageManifestoUuid, setMyAverageManifestoUuid] = useState<string>();
  const [needSave, setNeedSave] = useState(false);
  const { getStatement } = useStatementsContext();

  const setPublicCount = (publicCount: number) => {
    _setPublicCount(Math.min(Math.max(publicCount, 0), statements.length));
    setNeedSave(true);
  };

  const add = (statement: IStatement) => {
    setStatements((statements) => {
      return unique([...statements, statement]);
    });
    setNeedSave(true);
  };

  const remove = (statement: IStatement) => {
    setStatements((statements) => {
      return statements.filter((stm) => statement !== stm);
    });
    setNeedSave(true);
  };

  const contains = (statement: IStatement): boolean => {
    return !!statements.find((other) => other.id === statement.id);
  };

  const reorder = (from: number, to: number) => {
    if (statements.length < from || statements.length < to) {
      return;
    }
    setStatements((statements) => {
      let result = [...statements];
      const movedItem = result.splice(from, 1);
      result.splice(to, 0, movedItem[0]);
      return result;
    });
    setNeedSave(true);
  };

  const processMyAverageManifesto = useCallback(
    (data: any) => {
      setMyAverageManifestoUuid(data.uuid);
      _setPublicCount(data.publicCount);
      if (data?.statements.length) {
        setStatements(
          data.statements
            .map((id: number) => {
              return getStatement(id);
            })
            .filter((item: any) => !!item)
        );
      } else {
        setStatements([]);
      }
    },
    [getStatement]
  );

  useEffect(() => {
    setMyAverageManifesto((averageManifesto) => {
      return averageManifesto;
    });
  }, [statements, publicCount]);

  return (
    <AverageManifestoContext.Provider
      value={{
        myAverageManifesto,
        setPublicCount,
        add,
        remove,
        contains,
        reorder,
      }}
    >
      {children}
    </AverageManifestoContext.Provider>
  );
};

export const useAverageManifestoContext = () => useContext(AverageManifestoContext)!;
