import React, { FormEvent, useEffect, useState } from "react";
import { useAuthUserContext } from "../../context/UserContext";
import useQuery from "../../hooks/useQuery";
import PageHeader from "../App/PageHeader";
import styles from "./ResetPasswordPage.module.css";

const ResetPasswordPage: React.FC = () => {
  const rtk = useQuery("rtk");
  const [resetToken, setResetToken] = useState(rtk || "");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [password, setPassword] = useState<string>("");
  const {
    exchangeResetToken,
    updatePassword,
    loggedIn,
    error,
    statusMessage,
  } = useAuthUserContext();

  const submitResetToken = async (evt: FormEvent) => {
    evt.preventDefault();
    await exchangeResetToken(resetToken);
  };

  const submitNewPassword = async (evt: FormEvent) => {
    evt.preventDefault();
    if (!password) {
      return;
    }
    await updatePassword(password);
  };

  useEffect(() => {
    setShowPasswordField(loggedIn);
  }, [loggedIn]);

  return (
    <div className={styles.ResetPasswordPage}>
      <PageHeader>Reset password</PageHeader>
      {error && <p>{error}</p>}
      {statusMessage && <p>{statusMessage}</p>}
      {!showPasswordField && (
        <form className={styles.Form} onSubmit={submitResetToken}>
          <p>Your reset code</p>
          <input
            value={resetToken}
            onChange={(evt) => setResetToken(evt.target.value)}
          />
          <button type="submit" disabled={!resetToken}>
            Submit code
          </button>
        </form>
      )}
      {showPasswordField && (
        <form className={styles.Form} onSubmit={submitNewPassword}>
          <p>Update your password</p>
          <input
            value={password}
            type="password"
            onChange={(evt) => setPassword(evt.target.value)}
          />
          <button type="submit" disabled={!password}>
            Set password
          </button>
        </form>
      )}
    </div>
  );
};

export default ResetPasswordPage;
