import {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./PixelOverlay.module.css";
import cx from "classnames";
import randomInt from "../../randomInt";
// import { motion, useMotionValue } from "framer-motion";

const COLS = 20; // change this number only
const ROWS = COLS * 2; // too many because we're never that tall

const colors = [
  "rgb(235, 252, 61)",
  "rgb(207, 222, 55)",
  "rgb(132, 140, 38)",
  "rgb(98, 104, 31)",
  "rgb(154, 164, 43)",
];
const randomColor = () => {
  return colors[randomInt(0, 5)];
};
const generateColors = (len: number = ROWS * COLS) => {
  return [...new Array(len)].map(() => randomColor());
};
const generateOpacities = (len: number = ROWS * COLS) => {
  return [...new Array(len)].map(() => 0.0 + Math.random() * 0.7);
};

// const useManyMotionValues = (COUNT: number) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   return [...Array(COUNT)].map(() => useMotionValue(Math.random() * 0.7));
// };

export interface IPixelOverlayRef {
  update: () => void;
}
interface IProps {
  className?: string;
  style?: CSSProperties;
}
const PixelOverlay = forwardRef<IPixelOverlayRef, IProps>(
  ({ className, style }, ref) => {
    const [opacities, setOpacities] = useState(generateOpacities());
    // const motionopacities = useManyMotionValues(COLS * ROWS);
    const [colors] = useState(generateColors());

    useImperativeHandle(ref, () => ({
      update: () => {
        setOpacities(() => generateOpacities());
        // for (let i = 0; i < motionopacities.length; i++) {
        //   motionopacities[i].set(Math.random() * 0.7);
        // }
      },
    }));

    return (
      <div className={cx(styles.PixelOverlay, className)} style={style}>
        <svg
          viewBox={`0 0 ${COLS * 10} ${ROWS * 10}`}
          preserveAspectRatio="xMidYMid slice"
        >
          {[...new Array(COLS)].map((_, col) => {
            return [...new Array(ROWS)].map((_, row) => {
              const idx = row * COLS + col;
              return (
                <rect
                  key={idx}
                  x={col * 10}
                  y={row * 10}
                  width={10}
                  height={10}
                  style={{
                    fill: colors[idx],
                    opacity: opacities[idx],
                    // opacity: motionopacities[idx],
                  }}
                />
              );
            });
          })}
        </svg>
      </div>
    );
  }
);

export default PixelOverlay;
