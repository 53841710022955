import React from "react";

interface IProps {
  date: Date;
}
const FormatDate: React.FC<IProps> = ({ date }) => {
  return <time>{date.toLocaleString()}</time>;
};

export default FormatDate;
