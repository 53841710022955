import React from "react";

import styles from "./ShareButton.module.css";
import cx from "classnames";

import { ReactComponent as ShareIcon } from "../../assets/icon_shareStatement.svg";
import SharePanel from "./SharePanel";
import { useModalPopupContext } from "../../context/ModalPopupContext";

interface IProps {
  text?: string;
  url?: string;
  className?: string;
}

const ShareButton: React.FC<IProps> = ({ text, className, url }) => {
  const { setModalPopup } = useModalPopupContext();

  const hideSharePanel = () => {
    setModalPopup();
  };

  const showSharePanel = () => {
    setModalPopup(
      <SharePanel text={text} url={url} onClose={hideSharePanel} />
    );
  };

  return (
    <>
      <div
        className={cx(styles.ShareButton, className)}
        onClick={showSharePanel}
      >
        <ShareIcon />
      </div>
    </>
  );
};

export default ShareButton;
