import React from "react";

import styles from "./ContentSection.module.css";
import cx from "classnames";

interface IProps {
  className?: string;
}

const ContentSection: React.FC<IProps> = ({ className, children }) => {
  return (
    <section className={cx(styles.ContentSection, className)}>
      {children}
    </section>
  );
};

export default ContentSection;
