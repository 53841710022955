import img2 from "./caim_skins_02.png";
import img4 from "./caim_skins_04.png";
import img5 from "./caim_skins_05.png";
import img7 from "./caim_skins_07.png";
import img10 from "./caim_skins_10.png";
import img11 from "./caim_skins_11.png";
import img12 from "./caim_skins_12.png";
import img13 from "./caim_skins_13.png";
import img14 from "./caim_skins_14.png";

const images = [img2, img4, img5, img7, img10, img11, img12, img13, img14];

export default images;
