import React, { useState } from "react";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as FacebookSvg } from "../../assets/icon_fb.svg";
import { ReactComponent as WhatsappSvg } from "../../assets/icon_wa.svg";
import { ReactComponent as TwitterSvg } from "../../assets/icon_tw.svg";

import styles from "./SharePanel.module.css";
import cx from "classnames";

interface IProps {
  text?: string;
  url?: string;
  className?: string;
  onClose?: () => void;
}

const SharePanel: React.FC<IProps> = ({ text, url, className, onClose }) => {
  //   const location = useLocation();
  const [feedback, setFeedback] = useState<string>();
  // console.log(window.location, window.location.origin);
  const shareUrl = url ? url : window.location.href;
  const msg = `${text ?? ""}
${url}`.trim();

  const hasShare = !!(window.navigator as any).canShare;
  const doShare = () => {
    (window.navigator as any).share({ shareUrl, text, title: "C.AI.M" });
  };
  const copyLink = async () => {
    try {
      await window.navigator.clipboard?.writeText(shareUrl);
      setFeedback("Link copied to clipboard");
    } catch (ex) {
      setFeedback("Error copying link, use the location bar");
    }
  };

  return (
    <div
      className={cx(styles.SharePanel, className)}
      onClick={(evt) => evt.stopPropagation()}
    >
    {feedback && <span className={styles.Feedback}>{feedback}</span>}
      <span onClick={onClose} className={styles.CloseButton}>
        <CloseSvg />
      </span>
      {hasShare
        ? {
            // eslint-disable-next-line
          } && <a className={styles.ShareButton} onClick={() => doShare()}>share</a>
        : {
            // eslint-disable-next-line
          } && <a className={styles.ShareButton} onClick={() => copyLink()}>copy link</a>}
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}&quote=${encodeURIComponent(text ?? "")}`}
      >
        <FacebookSvg />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/?text=${encodeURIComponent(msg)}`}
      >
        <WhatsappSvg />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          msg
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        <TwitterSvg />
      </a>
    </div>
  );
};

export default SharePanel;
