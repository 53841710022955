import React from "react";
import styles from "./ColorShadow.module.css";
import cx from "classnames";

type XY = [x: number, y: number];
interface IProps {
  className?: string;
  maskFile: string;
  offset?: XY;
  scale?: number;
  blurSize?: number;
  opacity?: number;
}

const ColorShadow: React.FC<IProps> = ({
  children,
  className,
  maskFile,
  offset: [x, y] = [0, 0],
  scale = 3,
  blurSize = 4,
  opacity = 0.75,
}) => {
  return (
    <div className={cx(styles.Container, className)}>
      <div
        className={styles.Shadow}
        style={{
          transform: `scale(${scale}) translate(${x}px, ${y}px)`,
          filter: `blur(${blurSize}px)`,
          opacity: opacity,
        }}
      >
        <div
          style={{
            maskImage: `url('${maskFile}')`,
            WebkitMaskImage: `url('${maskFile}')`,
          }}
        ></div>
      </div>
      {children}
    </div>
  );
};

export default ColorShadow;
