import React from "react";

import styles from "./CommentsButton.module.css";
import cx from "classnames";
import { IStatement } from "../../context/StatementsContext";
import { ReactComponent as CommentsIcon } from "../../assets/icon_comments.svg";

interface IProps {
  statement: IStatement;
  className?: string;
  onClick?: () => void;
}

const CommentsButton: React.FC<IProps> = ({
  statement,
  className,
  onClick,
}) => {
  return (
    <div className={cx(className, styles.CommentsButton)} onClick={onClick}>
      <span>{statement.commentCount || 0}</span>
      <CommentsIcon />
      <label>comments</label>
    </div>
  );
};

export default CommentsButton;
