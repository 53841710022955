import { useState, useEffect } from "react";
import { IUser, useAuthUserContext, UserID } from "../context/UserContext";

const useUserProfile = (uuid: UserID) => {
  const { API } = useAuthUserContext();
  const [userProfile, setUserProfile] = useState<IUser>();

  useEffect(() => {
    API &&
      API.get(`/user/${uuid}/profile`).then((res) => setUserProfile(res.data));
  }, [API, uuid]);

  return userProfile;
};

export default useUserProfile;
