import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthUserContext } from "./UserContext";

export type PopupId = number;

export interface IPopup {
  id: PopupId;
  active: number;
  message: string;
}

export type OptionalIPopup = IPopup | undefined;

interface IPopupsContext {
  popups: IPopup[];
  setPopups: React.Dispatch<React.SetStateAction<IPopup[]>>;
  getPopup: (id: PopupId) => OptionalIPopup;
}

const PopupsContext = createContext<IPopupsContext | undefined>(
  undefined
);

export const PopupProvider: React.FC = ({ children }) => {
  const [popups, setPopups] = useState<IPopup[]>([]);
  const { API } = useAuthUserContext()

  const getPopup = useCallback(
    (id: PopupId): OptionalIPopup => {
      return popups?.[0];
    },
    [popups]
  );

  useEffect(() => {
    API && API.get("/popups").then((res) => setPopups(res.data));
  }, [API]);

  return (
    <PopupsContext.Provider
      value={{
        popups,
        setPopups,
        getPopup,
      }}
    >
      {children}
    </PopupsContext.Provider>
  );
};

export const usePopupsContext = () => useContext(PopupsContext)!;
