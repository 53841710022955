import React from "react";

import styles from "./PageHeader.module.css";
import cx from "classnames";

interface IProps {
  className?: string;
}

const PageHeader: React.FC<IProps> = ({ children, className }) => {
  return <h2 className={cx(styles.PageHeader, className)}>{children}</h2>;
};

export default PageHeader;
