import React from "react";
import styles from "./Breadcrumb.module.css";

interface IProps {
  linkStack: Array<JSX.Element>
}

const Breadcrumb: React.FC<IProps> = ({ linkStack }) => {

  return (
    linkStack && <div className={styles.Breadcrumb}>
      {linkStack}
    </div>
  );
};

export default Breadcrumb;
