import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { UserProvider } from "../../context/UserContext";
import { MenuProvider } from "../../context/MenuContext";
import { StatementProvider } from "../../context/StatementsContext";
import { ManifestoProvider } from "../../context/ManifestoContext";
import { AverageManifestoProvider } from "../../context/AverageManifestoContext";
import { UsersProvider } from "../../context/UsersContext";
import { PopupProvider } from "../../context/PopupsContext";

import styles from "./App.module.css";
import "./global.css";

import StatementPage from "../Statement/StatementPage";
import Header from "./Header";
import LoginPage from "../User/LoginPage";
import ProfilePage from "../User/ProfilePage";
import CreatePage from "../User/CreatePage";
import Menu from "./Menu";
import LogoutPage from "../User/LogoutPage";
import PopUp from "../UserInterfaceElements/PopUp";
import MyProfilePage from "../User/MyProfilePage";
import AddStatementPage from "../Statement/AddStatementPage";
import EditStatementPage from "../Statement/EditStatementPage";
import ModerateStatementsPage from "../Statement/ModerateStatementsPage";
import ModeratePopupsPage from "../Popups/ModeratePopupsPage";
import ModerateUsersPage from "../User/ModerateUsersPage";
import ModerateReactionsPage from "../Statement/ModerateReactionsPage";
import AverageManifestoPage from "../AverageManifesto/AverageManifestoPage";
import ManifestoPage from "../Manifesto/ManifestoPage";
import ModerationPage from "../Moderation/ModerationPage";
import ActionBar from "./ActionBar";
// import Backdrop from "../FX/Backdrop";
import RequestResetPage from "../User/RequestResetPage";
import ResetPasswordPage from "../User/ResetPasswordPage";
import HomePage from "./HomePage";
// import DashboardPage from "../Dashboard/DashboardPage";
import AboutPage from "./AboutPage";
import { ModalPopupProvider } from "../../context/ModalPopupContext";
import { BackFxProvider } from "../../context/BackFxContext";
import { AvatarPickerPage } from "../User/AvatarPicker";


function App() {
  return (
    <Router>
      <ModalPopupProvider>
        <MenuProvider>
          <UserProvider>
            <UsersProvider>
              <StatementProvider>
                <ManifestoProvider>
                  <AverageManifestoProvider>
                    <PopupProvider>
                      <PopUp />
                      <div className={styles.App}>
                        <BackFxProvider backdropClassName={styles.Backdrop}>
                          <Header className={styles.Header} />
                          <Menu className={styles.Menu} />
                          <div className={styles.Container}>
                            <Switch>
                              <Route exact path="/" component={HomePage} />
                              <Route exact path="/about" component={AboutPage} />
                              {/* <Route exact path="/dashboard" component={DashboardPage} /> */}
                              <Route
                                path="/statement/add"
                                exact
                                component={AddStatementPage}
                              />
                              <Route
                                path="/statement/:id/edit"
                                exact
                                component={EditStatementPage}
                              />
                              <Route
                                path="/statement/:id"
                                component={StatementPage}
                              />
                              <Route path="/login" exact component={LoginPage} />
                              <Route path="/logout" exact component={LogoutPage} />
                              <Route path="/signup" exact component={CreatePage} />
                              <Route
                                path="/account/me"
                                exact
                                component={MyProfilePage}
                              />
                              <Route path="/account/:uuid" component={ProfilePage} />
                              <Route
                                path="/common-manifesto"
                                component={AverageManifestoPage}
                              />
                              <Route
                                path="/manifesto/:id"
                                component={ManifestoPage}
                              />
                              <Route
                                path="/password/forgot"
                                component={RequestResetPage}
                              />
                              <Route
                                path="/password/reset"
                                component={ResetPasswordPage}
                              />
                              <Route
                                path="/avatarpicker"
                                component={AvatarPickerPage}
                              />
                              {/*
                                Admin stuffs
                              */}
                              <Route
                                path="/moderate"
                                exact
                                component={ModerationPage}
                              />
                              <Route
                                path="/moderate/:id/reactions"
                                exact
                                component={ModerateReactionsPage}
                              />
                              <Route
                                path="/moderate/statements"
                                exact
                                component={ModerateStatementsPage}
                              />
                              <Route
                                path="/moderate/statement/:id/edit"
                                exact
                                component={EditStatementPage}
                              />
                              <Route
                                path="/moderate/popups"
                                exact
                                component={ModeratePopupsPage}
                              />
                              <Route
                                path="/moderate/users"
                                exact
                                component={ModerateUsersPage}
                              />
                              {/* <Route
                            path="/password/update"
                            component={UpdatePasswordPage}
                          /> */}
                            </Switch>
                          </div>
                          <ActionBar className={styles.ActionBar} />
                        </BackFxProvider>
                      </div>
                    </PopupProvider>
                  </AverageManifestoProvider>
                </ManifestoProvider>
              </StatementProvider>
            </UsersProvider>
          </UserProvider>
        </MenuProvider>
      </ModalPopupProvider>
    </Router>
  );
}

export default App;
