import React from "react";

import { useAverageManifestoContext } from "../../context/AverageManifestoContext";
import { IStatement } from "../../context/StatementsContext";
import { useAuthUserContext } from "../../context/UserContext";
import useAverageManifesto from "../../hooks/useAverageManifesto";

import styles from "./AverageManifestoPage.module.css";

import { ReactComponent as AddToAverageManifestoIcon } from "../../assets/icon_addToManifesto.svg";
import StatementRow from "./../UserInterfaceElements/StatementRow";
import PageHeader from "../App/PageHeader";
import ShareButton from "../Share/ShareButton";


const AverageManifestoPage: React.FC = () => {
  const { add, contains } = useAverageManifestoContext();
  const { user: loggedInUser } = useAuthUserContext();
  const { averageManifesto } = useAverageManifesto();


  if (!averageManifesto) {
    return <div>loading....</div>;
  }

  let publicAverageManifesto = averageManifesto.statements || [];
 

  const makeStatement = (statement: IStatement, idx: number) => (
    <div key={idx} className={styles.AverageManifestoItem}>
      <StatementRow
        statement={statement}
        linkToStatementPage={true}
        // textClassName={styles.StatementText}
      />
      {/* {loggedInUser && (
        <span>
          {contains(statement) ? (
            <>in your averageManifesto</>
          ) : (
            <span onClick={() => add(statement)}>
              <AddToAverageManifestoIcon />
            </span>
          )}
        </span>
      )} */}
    </div>
  );


  return (
    <div className={styles.AverageManifestoPage}>
      <PageHeader className={styles.PageHeader}>
        The Common Manifesto
      </PageHeader>
      <ShareButton
        text={`Check the critical AI Manifesto`}
        url={window.location.href}
        className={styles.ShareButton}
      />
      {publicAverageManifesto.map(makeStatement)}
      {/*isMyAverageManifesto && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="public">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {publicAverageManifesto.map(makeDraggableStatement)}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="drop-sep">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable draggableId="drag-sep" index={0}>
                  {(provided) => (
                    <li
                      className={styles.PublicCountSeparator}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <label>public</label>
                      <button
                        disabled={averageManifesto.publicCount <= 0}
                        onClick={() =>
                          setPublicCount(averageManifesto.publicCount - 1)
                        }
                      ></button>
                      <hr />
                      <label>private</label>
                      <button
                        disabled={
                          averageManifesto.publicCount >= averageManifesto.statements.length
                        }
                        onClick={() =>
                          setPublicCount(averageManifesto.publicCount + 1)
                        }
                      ></button>
                      <span>
                        <BarDragIcon />
                      </span>
                    </li>
                  )}
                </Draggable>
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="private">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {privateAverageManifesto.map((statement, idx) =>
                  makeDraggableStatement(statement, idx)
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) */}
    </div>
  );
};

export default AverageManifestoPage;
