import React from "react";

import styles from "./AboutPage.module.css";
import PageHeader from "./PageHeader";

const AboutPage: React.FC = () => {
  return (
    <div className={styles.AboutPage}>
      <PageHeader className={styles.AboutPageHeader}>C.AI.M</PageHeader>
      <h3 className={styles.AboutPageSubHeader}> by United Intelligence Lab</h3>
      <p>
        The United Intelligence Lab considers AI to be the most transformative
        technology of our time that shapes the way we interact, create and
        think. With C.AI.M we want to study and exploit AI, unmasking its
        impact.
      </p>
      <p>
        UIL is an ever growing group of people cooperating on the Critical AI
        Manifesto exploring the impact of machine learning on culture and
        society. UIL members are from all walks of life. Whether AI-dominion is
        something you secretly yearn for or desperately hope to escape from, one
        thing is for certain, the old vantage points on AI will for nobody
        suffice anymore. Join us in writing C.AI.M. Anyone is welcome, except if
        you are an AI.
      </p>
    </div>
  );
};

export default AboutPage;
