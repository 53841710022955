import React, { useEffect, useState } from "react";
import { IUser, useAuthUserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";


import styles from "./Avatar.module.css";
import cx from "classnames";

interface IProps {
  imageName?: string;
  user?: IUser;
  className?: string;
  showLink?: string;
}
const Avatar: React.FC<IProps> = ({ user, className, imageName, showLink="" }) => {
  const link = showLink ? showLink : user ? `/account/${user.uuid}` : "/login";

  const { API } = useAuthUserContext();
  const [avatarImgSrc, setAvatarImgSrc] = useState<string>("");

  useEffect(() => {
      
    if (imageName) {
      setAvatarImgSrc(`/avatars/${imageName}`);
    } else if (user && user.avatar) {
      setAvatarImgSrc(`/avatars/${user.avatar}`);
    } else {
      API?.get("/avatars").then((res) => {
        res.data && setAvatarImgSrc(`/avatars/${res.data[Math.floor(Math.random() * res.data.length)]}`);
      });
    }
  }, [API]);

  if (user ) {
    return (
      <Link to={link} className={cx(styles.link_gravatar, className)}>
        {/* <img className={styles.AvatarImage} src={avatarImgSrc} alt="avatar" /> */}
        <img className={styles.AvatarMaskedImage} src={avatarImgSrc} alt="avatar"  style={{backgroundImage: avatarImgSrc}} />
        {/* <div className={styles.AvatarImage} ></div> */}
      </Link>
    );
  } else {
    //return <div className={styles.AvatarMaskedImage}  style={{backgroundImage: avatarImgSrc}} />;
    return <span className={className}>
      <img className={styles.AvatarMaskedImage} src={avatarImgSrc} alt="avatar"  style={{backgroundImage: avatarImgSrc}}  />
    </span>;
    // return <img className={styles.AvatarImage} src={avatarImgSrc} alt="avatar" />;
  }
};

export default Avatar;
