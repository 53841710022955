import { useState, useEffect } from "react";
import { useAverageManifestoContext, IAverageManifesto } from "../context/AverageManifestoContext";
import {
  IStatement,
  OptionalIStatement,
  StatementId,
  useStatementsContext,
} from "../context/StatementsContext";
import { useAuthUserContext } from "../context/UserContext";

const useAverageManifesto = () => {
  const { myAverageManifesto } = useAverageManifestoContext();
  const { getStatement } = useStatementsContext();
  const { API } = useAuthUserContext();
  const [averageManifesto, setAverageManifesto] = useState<IAverageManifesto>();
  const [statementIds, setStatementIds] = useState<StatementId[]>();

  useEffect(() => {
    setAverageManifesto(myAverageManifesto);
  }, [myAverageManifesto]);

  useEffect(() => {
    if (!statementIds) {
      return;
    }
    setAverageManifesto((_averageManifesto) => {
      if (!_averageManifesto) {
        return _averageManifesto;
      }
      let averageManifesto: IAverageManifesto = { ..._averageManifesto };
      averageManifesto.statements = statementIds
        .map((id: number) => {
          return getStatement(id);
        })
        .filter((item: OptionalIStatement) => !!item) as IStatement[];
      return averageManifesto;
    });
  }, [statementIds, getStatement]);

  useEffect(() => {
      API &&
        API.get(`/average-manifesto/`).then((res) => {
          const ids = res.data?.statements;
          const data = res.data;
          data.statements = [];
          setAverageManifesto(data);
          setStatementIds(ids);
        });
  }, [API]);

  return { averageManifesto, reload: () => {} };
};

export default useAverageManifesto;
