import { useLocation } from "react-router-dom";

const useQuery = (name: string) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(name) || undefined;
};

export const useHash = (name: string) => {
  let hash = useLocation().hash;
  if (hash && hash.length) {
    hash = hash.substring(1);
  } else {
    return undefined;
  }
  const search = new URLSearchParams(hash);
  return search.get(name) || undefined;
};

export default useQuery;
