import { useCallback, useState } from "react";
import { IPopup, usePopupsContext } from "../context/PopupsContext";
import { useAuthUserContext } from "../context/UserContext";

const useModeratePopup = () => {
  const { setPopups } = usePopupsContext();
  const [popup, setPopup] = useState<IPopup>();
  const { API } = useAuthUserContext();

  const moderate = useCallback(
    (id:string, active: boolean, message: string, ) => {
      API!.post(`/popups/${id}/moderate`, { id, message, active }).then((res) => {
        const newPopup: IPopup[] = res.data;
        setPopup(newPopup[0]);
      });
    },
    [API, setPopups, popup]
  );

  return { popup, moderate };
};

export default useModeratePopup;
