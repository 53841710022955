import React, { FormEvent, useState } from "react";
import { useAuthUserContext } from "../../context/UserContext";
import PageHeader from "../App/PageHeader";

import styles from "./RequestResetPage.module.css";

const RequestResetPage: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const { API, error } = useAuthUserContext();
  const [didRequest, setDidRequest] = useState(false);

  const requestReset = async (evt: FormEvent) => {
    evt.preventDefault();
    if (!API) {
      return;
    }
    await API.post("/user/password/forgot", { email });
    setDidRequest(true);
  };

  return (
    <div className={styles.RequestResetPage}>
      <PageHeader>Forgot password?</PageHeader>
      {error && <p>{error}</p>}
      {didRequest && <p>Reset requested, check your inbox</p>}
      <form className={styles.Form} onSubmit={(evt) => requestReset(evt)}>
        <input
          type="text"
          className={styles.EmailInput}
          placeholder="e-mail"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
        <button type="submit">reset password</button>
      </form>
    </div>
  );
};

export default RequestResetPage;
