import React from "react";

import styles from "./Blur.module.css";
import cx from "classnames";

type XY = [x: number, y: number];
interface IProps {
  className?: string;
  blurClassName?: string;
  offset?: XY;
  blurSize?: number;
  scale?: number;
  opacity?: number;
}
const Blur: React.FC<IProps> = ({
  children,
  className,
  blurClassName,
  offset: [x, y] = [0, 0],
  blurSize = 3,
  scale = 1.1,
  opacity = 0.5,
}) => {
  return (
    <div className={cx(styles.Container, className)}>
      <div
        className={cx("CaimBlurred", styles.Blur, blurClassName)}
        style={{
          transform: `scale(${scale}) translate(${x}px, ${y}px)`,
          filter: `blur(${blurSize}px)`,
          opacity: opacity,
        }}
      >
        {children}
      </div>
      <div className={styles.Original}>{children}</div>
    </div>
  );
};

export default Blur;
