import React from "react";
import { useAuthUserContext } from "../../context/UserContext";
import { Redirect } from "react-router-dom";

import styles from "./LogoutPage.module.css";
import PageHeader from "../App/PageHeader";

const LogoutPage: React.FC = () => {
  const { error, logout, loggedIn } = useAuthUserContext();

  const doLogout = async () => {
    try {
      logout();
    } catch (ex) {
      console.error(ex);
    }
  };

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={styles.LogoutPage}>
      <PageHeader>Logout</PageHeader>
      {error && <p>{error}</p>}
      <button onClick={doLogout}>Logout</button>
    </div>
  );
};

export default LogoutPage;
