import React, { Children } from "react";
import styles from "./Button.module.css";

interface IProps {
  onClick: Function;
  disabled?: boolean | undefined;
  children: JSX.Element;
}

const Button: React.FC<IProps> = ({ onClick, disabled,children  }) => {

  return (
    <button
      disabled={disabled}
      onClick={(e) => onClick() }
      className={styles.Button}
    >{children}</button>
  );
};

export default Button;
