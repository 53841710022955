import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useStatementsStack, {
  StatementsStack,
} from "../hooks/useStatementsStack";
import { useAuthUserContext } from "./UserContext";

export type StatementId = number;

export interface IStatement {
  id: StatementId;
  text: string;
  commentCount?: number;
  source?: string;
  sourceLink?: string;
  submittedBy: string;
  updated: string;
  deleted?: boolean
}

export type OptionalIStatement = IStatement | undefined;

interface IStatementsContext {
  statements: IStatement[];
  setStatements: React.Dispatch<React.SetStateAction<IStatement[]>>;
  getStatement: (id: StatementId) => OptionalIStatement;
  stack: StatementsStack;
}

const StatementsContext = createContext<IStatementsContext | undefined>(
  undefined
);

export const StatementProvider: React.FC = ({ children }) => {
  const [statements, setStatements] = useState<IStatement[]>([]);
  const { API } = useAuthUserContext();
  const stack = useStatementsStack(statements);

  const getStatement = useCallback(
    (id: StatementId): OptionalIStatement => {
      return statements?.find((statement) => statement.id === id);
    },
    [statements]
  );

  useEffect(() => {
    API && API.get("/statements").then((res) => setStatements(res.data));
  }, [API]);

  return (
    <StatementsContext.Provider
      value={{
        statements,
        setStatements,
        getStatement,
        stack,
      }}
    >
      {children}
    </StatementsContext.Provider>
  );
};

export const useStatementsContext = () => useContext(StatementsContext)!;
