import React from "react";
import { Link } from "react-router-dom";
import { IStatement } from "../../context/StatementsContext";
import { useAuthUserContext } from "../../context/UserContext";
import { useLoadComments, useModerateComment } from "../../services/ReactionService";
import FormatDate from "../App/FormatDate";
import CommentAdd from "./CommentAdd";

import styles from "./CommentsListing.module.css";

interface IProps {
  statement: IStatement,
  inputComment?: boolean
}

const CommentsListing: React.FC<IProps> = ({ statement, inputComment = true }) => {
  const { comments, refresh } = useLoadComments(statement.id);
  const { loggedIn, user } = useAuthUserContext();
  const { moderate } = useModerateComment(statement.id);

  const deleteComment = (commentId:Number, deleteStatus:Boolean) => {
    const succes = moderate(commentId, deleteStatus);
    //console.log('succes', succes)
    refresh();
  }

  return (
    <div className={styles.CommentsListing}>
      {inputComment && loggedIn && (
        <CommentAdd statement={statement} onCommentAdded={() => refresh()} />
      )}
      {!loggedIn && (
        <Link to={`/login?redirect=/statement/${statement.id}#comments=true`} className={styles.LoginToComment}>
          Login to comment
        </Link>
      )}
      {comments?.map((comment) => (
        <div className={styles.Comment} key={comment.id}>
          <p style={comment.deleted ? {textDecoration:"line-through"} : {}}>{comment.comment}</p>
          <div className={styles.MetaData} >
            <FormatDate date={comment.timestamp} />{" "}
            {comment.user ? (
              // <Link to={`/account/${comment.user.uuid}`}>
              <Link to={`#`}>
                <div className={styles.Avatar} style={{backgroundImage:`url(${'/avatars/'+comment.user.avatar}`}} /> {comment.user.name}
              </Link>
            ) : (
              "unknown"
            )}
          </div>
          <div  className={styles.Moderation}>
            {user && user.moderator && comment.deleted ? 
              <button onClick={() => deleteComment(comment.id, false)}>
                <span className={styles.ApproveIcon}>✔</span>
                <span className={styles.ButtonText}>re-instate</span>
              </button> : 
              <button onClick={() => deleteComment(comment.id, true)}>
                <span className={styles.DeleteIcon}>❌</span>
                <span className={styles.ButtonText}>remove</span>
              </button>
            }
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentsListing;
