import { AnimatePresence, motion } from "framer-motion";
import React, { createContext, ReactNode, useContext, useState } from "react";
import styles from "./ModalPopup.module.css";

interface IModalPopupContext {
  setModalPopup: (element?: ReactNode) => void;
}

const ModalPopupContext = createContext<IModalPopupContext | undefined>(
  undefined
);

export const ModalPopupProvider: React.FC = ({ children }) => {
  const [modalPopup, setModalPopup] = useState<ReactNode>();

  return (
    <ModalPopupContext.Provider value={{ setModalPopup }}>
      {children}
      <AnimatePresence>
        {modalPopup && (
          <motion.div
            onClick={() => setModalPopup(undefined)}
            className={styles.ModalContainer}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: "50%" }}
            exit={{ opacity: 0, y: "-50%" }}
            transition={{ duration: 0.2 }}
          >
            {modalPopup}
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPopupContext.Provider>
  );
};

export const useModalPopupContext = () => useContext(ModalPopupContext)!;
