import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthUserContext, UserID } from "../../context/UserContext";
import useUserProfile from "../../hooks/useUserProfile";
import ContentSection from "../App/ContentSection";
import PageHeader from "../App/PageHeader";
import Avatar from "./Avatar";

import { useManifestoContext } from "../../context/ManifestoContext";
import { IStatement } from "../../context/StatementsContext";
import useManifesto from "../../hooks/useManifesto";
import stylesManifesto from "./../Manifesto/ManifestoPage.module.css";
import { ReactComponent as AddToManifestoIcon } from "../../assets/icon_addToManifesto.svg";
import StatementRow from "./../Manifesto/StatementRow";
import ShareButton from "../Share/ShareButton";

import styles from "./ProfilePage.module.css";

interface RouteParams {
  uuid: UserID;
}

const ProfilePage: React.FC = () => {
  const { uuid } = useParams<RouteParams>();
  const { user: loggedInUser } = useAuthUserContext();
  const userProfile = useUserProfile(uuid);
  const [manifestoId, setManifestoId] = useState("");
  
  //const { id } = useParams<IRouteParams>();
  const { add, contains } = useManifestoContext();
  //console.log(userProfile, userProfile && userProfile.manifestoIds && userProfile.manifestoIds.length ? userProfile.manifestoIds[0] : "");
  
  useEffect(() => {
    userProfile && userProfile.manifestoIds && userProfile.manifestoIds.length && setManifestoId( userProfile.manifestoIds[0]);
  }, [userProfile])

  const { manifesto } = useManifesto( manifestoId );

  if (!userProfile) {
    return <div className={styles.ProfilePage}>No user</div>;
  }
  if (!manifesto) {
    return <div>loading....</div>;
  }


  let publicManifesto = manifesto.statements || [];

  const makeStatement = (statement: IStatement, idx: number) => (
    <li key={idx} className={stylesManifesto.ManifestoItem}>
      <StatementRow
        statement={statement}
        textClassName={stylesManifesto.StatementText}
      />
      {loggedInUser && (
        <span>
          {contains(statement) ? (
            <>in your manifesto</>
          ) : (
            <span onClick={() => add(statement)}>
              <AddToManifestoIcon />
            </span>
          )}
        </span>
      )}
    </li>
  );


  return (
    <div className={styles.ProfilePage}>
      {/* <PageHeader>Profile</PageHeader> */}
      <Avatar user={userProfile} />
      {loggedInUser?.uuid && loggedInUser.uuid === userProfile.uuid && (
        <ContentSection>
          <><p>
              This is your account.
            </p>
            <p>
              <Link className={styles.EditButton} to="/account/me">edit</Link>
            </p></> 
        </ContentSection>
      )}

      <div className={stylesManifesto.ManifestoPage}>
        <PageHeader className={stylesManifesto.PageHeader}>
          {`Manifesto of ${manifesto.creator?.name}`}
        </PageHeader>
        <ShareButton
          text={`Check this critical AI manifesto of ${manifesto.creator?.name}`}
          url={ window.location.href.replace(/me$/, manifesto.uuid ?? "me")}
          className={stylesManifesto.ShareButton}
        />
        <ul>{publicManifesto.map(makeStatement)}</ul>
      </div>
    </div>
  );
};

export default ProfilePage;
