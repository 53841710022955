import { useState, useEffect } from "react";
import {
  OptionalIStatement,
  StatementId,
  useStatementsContext,
} from "../context/StatementsContext";

const useStatement = (id: StatementId): OptionalIStatement => {
  const { statements } = useStatementsContext();
  const [statement, setStatement] = useState<OptionalIStatement>();

  useEffect(() => {
    setStatement(statements?.find((statement) => statement.id === id));
  }, [id, statements]);

  return statement;
};

export default useStatement;
